<template>
  <div class="direct-page">
    <div class="title">直接用餐</div>
    <van-form ref='form'>
      <van-field label="吃餐人" v-model="username" disabled name="username"></van-field>
      <van-radio-group v-model="type">
        <van-cell-group>
          <van-cell title="午餐" clickable @click="type = 'lunch'" style="text-align:left">
            <van-radio slot="right-icon" name="lunch" />
          </van-cell>
          <van-cell title="晚餐" clickable @click="type = 'dinner'" style="text-align:left">
            <van-radio slot="right-icon" name="dinner" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-field label="备注" v-model="note" placeholder="请输入备注" :rules="noteRules"></van-field>
      <van-button type="info" block style="margin-top:10px;" :disabled="isDisabled" @click="submit">确认用餐</van-button>
  </van-form>
  </div>
</template>
<script>
//import * as dd from 'dingtalk-jsapi';
import Vue from 'vue';
import { Form,Field,RadioGroup, Radio,Cell,CellGroup,Toast,Dialog,Loading } from 'vant';
import { getMealEatInfo } from '@/api'

Vue.use(Form);
Vue.use(Field)
Vue.use(Loading)
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Cell).use(CellGroup);
export default {
  name:'direct',
  data(){
    return {
      username:'',
      type:'lunch',
      note:'',
      typeList:[
        { text: '午餐', value: 'lunch' },
        { text: '晚餐', value: 'dinner' }
      ],
      noteRules:[{
        required: true,
        message: '',
        trigger: 'onBlur'
      }],
      isDisabled:false
    }
  },
  mounted(){
    this.init()
  },
  methods:{
    init(){
      this.username = this.$route.params.name
    },
    submit(){
      this.isDisabled = true
      this.$refs.form.validate().then(async () => {
        let params = {
          user:this.username,
          type:this.type,
          note:this.note
        }
        let res = await getMealEatInfo(params)
        if(res.code == '200') {
          this.isDisabled = true
          Dialog.alert({
            confirmButtonText: '关闭',
            message: res.result + '，祝您用餐愉快！'+ '\n 备注：此弹窗需给打饭工作人员查看，\n确认后再关闭！',
          }).then(() => {
            this.$router.push({
              name: "Home",
            })
          })
        }else{
          this.isDisabled = false
          Toast.fail('提交失败，请重试！');
        }
      }).catch(() => {
        this.isDisabled = false
        this.$toast.fail('必填项不能为空！')
      })
      
      
    }
  },
}
</script>
<style scoped>
  .direct-page {
    padding: 10px 20px;
  }
  .title {
    margin: 10px 0 20px 0;
    color: #40a9ff;
    font-size: 22px;
  }
</style>